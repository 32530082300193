.header {
  @media screen and (max-width: 480px) {
    height: 230px;
    padding: 1em 10px;
  }
  height: 210px;
  width: 90vw;
  max-width: 1400px;
  margin: 2em auto;
  margin-top: 2.4em;
  padding: 10px 3rem;

  border-radius: 3px;
  background-color: #0180ff;
  // background-color: #5c07ff;
  // padding: 95px;

  h4,
  h1 {
    text-align: center;
    color: #fff;
    // margin-top: -87px;
    padding: 0;
    display: block;
    font-weight: 600;
    margin: 0;
    font-size: 1.5rem;

    @media screen and (max-width: 714px) {
      font-size: 0.95em;
    }
  }
  h6 {
    font-weight: 600;
    margin-bottom: 3px;
    @media screen and (max-width: 714px) {
      font-size: 0.9em;
      text-align: center;
    }
  }

  hr {
    // margin-top: -2px;
    // margin-right: -100px;
    // margin-left: -100px;
    border: 0;
    height: 1px;
    background: linear-gradient(to right, transparent, #fff, transparent);
    opacity: 0.2;
    margin: 10px 0;
  }

  @media screen and (max-width: 980px) {
    .categories {
      // margin-right: -90px;
      // margin-left: -90px;
      border: 0;
    }
  }

  .categories {
    padding: 10px;
    padding-bottom: 0;
    // background-color: /*#fbc216*/ #1647fb;
    color: #fff;
    border-radius: 2px;
    height: calc(100% - 35px);

    .catTitles {
      background-color: #1647fb;
      border-radius: 3px;
    }
  }

  .checkbox {
    // width: 100%;
    position: relative;
    display: inline-block;
    min-width: 95px;
    margin-bottom: 8px;

    label {
      //font-size: 0.9em;
      position: relative;
      display: inline-block;
      padding-left: 25px;
      margin: 0;
      line-height: 1;
      @media screen and (max-width: 980px) {
        padding-left: 20px;
      }
      font-weight: normal;
      cursor: pointer;
      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 15px;
        height: 15px;
        transition: transform 0.28s ease;
        //border: 1px solid #fff;
        border: 2px solid #f6f6f6;
        border-radius: 2px;
        background-color: transparent;
      }
      &:after {
        content: "";
        display: block;
        width: 11px;
        height: 7px;
        border-bottom: 2px solid #fbc216;
        border-left: 2px solid #fbc216;
        transform: rotate(-45deg);
        opacity: 0;
        transition: opacity ease 0.25s;
        position: absolute;
        top: 2px;
        left: 1px;
      }
    }
    input[type="checkbox"] {
      width: auto;
      opacity: 0;
      position: absolute;
      left: 0;
      top: 50%;
      transform: transitionY(-50%);

      &:checked ~ label {
        &:before {
          border: 2px solid #fbc216;
        }
        &:after {
          opacity: 1;
        }
      }
      &:focus + label::before {
        outline: 0;
      }
    }
  }
}

@media screen and (max-width: 700px) {
}
