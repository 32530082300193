.boardCard {
  position: relative;
  // width: 285px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-height: 425px;
  margin-bottom: 30px;
  margin-top: 0;
  padding: 0;
  border-radius: 5px;
  //border: 1px solid rgba(#888, 0.2);
  box-shadow: 0px 3px 6px #00000029;
  // transform: scale(0.95);
  transition: 0.25s ease-in-out;
  background: #f5f4f1;

  @media screen and (max-width: 645px) {
    width: 90vw;
  }

  &:hover {
    // box-shadow: -5px 5px 10px 3px #00000029;
    box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24),
      0 17px 50px 0 rgba(0, 0, 0, 0.19);

    .boardImg::before {
      background-color: rgba(#000, 0);
    }
  }

  .availability {
    position: absolute;
    top: 0;
    right: 0;
    padding: 3px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: green;
    color: #eee;
    font-size: 13px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 4px;
  }

  .indisponible {
    position: absolute;
    top: 0;
    right: 0;
    padding: 3px 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #dc3545; //#fbc216;
    color: #eee;
    font-size: 10px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 4px;
  }

  .boardImg {
    position: relative;
    min-height: 232px;
    max-height: 273px;
    width: 100%;
    background: #ccc no-repeat center;
    background-size: cover;
    border-radius: 5px 5px 0 0;
    cursor: pointer;
    //cursor: zoom-in;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(#000, 0.25);
      border-radius: 5px 5px 0 0;
      transition: 0.25s ease-in-out;
    }
  }

  summary {
    margin: 5px 0;
    padding: 15px 10px;
    padding-right: 17px;
    cursor: default;

    small {
      font-size: smaller;
      //color: #707070;
      color: #5076ff;
    }

    .icon {
      width: 22px;
      margin-right: 10px;
      margin-left: 2px;

      img {
        max-width: 100%;
      }
    }

    .firstLine {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
    }
    .location,
    .price {
      display: flex;
      align-items: center;

      svg {
        width: 22px;
        height: 22px;
        margin-right: 5px;
      }

      svg + div {
        div {
          font-size: small;
        }
      }
    }
    .common {
      font-weight: 600;
      line-height: 1;
      font-size: 1rem !important;
    }
  }

  footer {
    border-top: 1px dashed #5076ff;
    padding: 10px 0;
    margin: 0px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > button {
      display: flex;
      align-items: center;
      outline: 0;
      box-shadow: none;
      border: none;
      background-color: transparent;

      svg,
      img {
        width: 18px;
        height: 18px;
      }

      span {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      small {
        margin-left: 5px;
        font-size: smaller;
        //color: #707070;
        color: #5076ff;
        font-weight: 600;
      }

      &:hover small {
        text-decoration: underline;
      }
    }

    .detailsBtn {
      padding: 3px 10px;
      border: 1px solid #b7e0f6;
      background-color: #b7e0f6;
      color: #5076ff;
      border-radius: 3px;
      transition: 0.4s;
      //padding-bottom: 5px;
      padding-top: 0px;

      small {
        font-weight: 600;
      }

      &:hover {
        background-color: #5076ff;
        color: #fff;
        box-shadow: 0 1px 10px 1px rgba(#222, 0.25);
      }
    }
  }

  .added {
    color: #fbc216;
    cursor: pointer;
  }
}
